import * as Yup from "yup";
import { DROPDOWN_SELECT } from "@/outscal-commons-frontend/FormBuilder/InputTypes";

const commonData = {
  customData: {
    showSearchInput: false,
    isMultiSelect: false,
    primaryColor: "#067519",
    secondaryColor: "#e5f8e5",
  },
  inputType: DROPDOWN_SELECT,
};

export const FormData = {
  level: {
    ...commonData,
    label: "Difficulty Level",
    name: "level",
    optionsList: [
      { id: "beginner", label: "Beginner" },
      { id: "intermediate", label: "Intermediate" },
      { id: "advanced", label: "Advanced" },
    ],
    validation: Yup.object().required(),
  },
  type: {
    ...commonData,
    customData: {
      ...commonData.customData,
      isMultiSelect: true,
    },
    label: "Content Type",
    name: "type",
    optionsList: [
      { id: "assessment", label: "Assessment" },
      { id: "scholarship", label: "Scholarship" },
      { id: "project", label: "Project" },
      { id: "course", label: "Course" },
      { id: "specialization", label: "Specialization" },
    ],
    validation: Yup.object().required(),
  },
  skills: {
    ...commonData,
    customData: {
      ...commonData.customData,
      isMultiSelect: true,
    },
    label: "Skills",
    name: "skills",
    optionsList: [
      { id: "c++", label: "C++" },
      { id: "c#", label: "C#" },
      { id: "dsa", label: "DSA" },
      { id: "oops", label: "OOPS" },
      { id: "unity", label: "Unity" },
      { id: "design-patterns", label: "Design Patterns" },
    ],
    validation: Yup.object().required(),
  },
};
