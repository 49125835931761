import React from "react";
import { FormData } from "./CoursesListingFilters.model";
import JobFiltersView from "@/mvcComponents/Commons/Jobs/JobFilterSection/JobFilters/JobFilters.view";
// import layoutStyles from "@/styles/LayoutStyles.module.css";
import styles from './CoursesListingFilters.module.css'

const CoursesListingFilters = ({ setFilters, filters, setAppliedFilters }) => {
  return (
    <div className={styles.courseFilters}>
      <JobFiltersView
        filterUIObj={Object.values(FormData)}
        setFilters={(name, value) => {
          setFilters((prevState) => ({ ...prevState, [name]: value }));
        }}
        clearFilters={() => {
          setAppliedFilters({});
          setFilters({});
        }}
        filtersRef={null}
        filters={filters}
        setFiltersInQuery={() => {
          setAppliedFilters(filters);
        }}
      />
    </div>
  );
};

export default CoursesListingFilters;
