import { ASSESSMENT_POPUP } from "@/store/actions/popupAction";
import { NetworkManager } from "@/outscal-commons-frontend";
import { removeDecimals } from "@/utils/commonUtils";

class AssessmentListingCardController {
    constructor(showPopup) {
        this.showPopup = showPopup;
    }

    async getLatestResponse(auth, assessmentItem, setUserAssessmentResponse) {
        if (auth?.token) {
            try {
                const resp = await NetworkManager.get(
                    `/assessment-response/getLatestResponse/${assessmentItem._id}`
                );

                setUserAssessmentResponse(resp?.data);
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            setUserAssessmentResponse(null);
        }

    };

    setScoreAndTagText(userAssessmentResponse, setScore, setTagText) {
        if (!userAssessmentResponse) {
            setScore(null);
            setTagText();
            return;
        }

        if (userAssessmentResponse?.total_score >= 0) {
            let text = "FAIL";
            if (userAssessmentResponse?.total_score >= 80) {
                text = "PASS";
            }
            setScore(removeDecimals(userAssessmentResponse?.total_score));
            setTagText(text);
        }
    }

    onAssessmentCardClick(e, score, assessmentItem, failedAssessmentCTA, userAssessmentResponse) {
        if (!score) {
            e.preventDefault();
            this.showPopup(ASSESSMENT_POPUP, {
                assessmentDisplayData: assessmentItem,
                userAssessmentResponse,
                getLatestUserResponse: this.getLatestResponse,
                upskillCourseSlug: failedAssessmentCTA?.slug,
                upskillCourseToShow: failedAssessmentCTA,
            });
        } else if (score > 80) {
            e.preventDefault();
        }
    };

}

export default AssessmentListingCardController;
