export const courseListingMap = {
  Specialization: { styleClass: "specialization" },
  Assessment: { styleClass: "assessment" },
  Scholarship: { styleClass: "scholarship" },
  Project: { styleClass: "project" },
  Course: { styleClass: "course" },
};

export const difficultyColorsMap = {
  Beginner: {
    primaryColor: "textGreen",
    secondaryColor: "lightBgGreen",
    icon: "MdOutlineSignalCellularAlt2Bar",
  },
  Intermediate: {
    primaryColor: "darkMud",
    secondaryColor: "seoLightBgYellow",
    icon: "MdOutlineSignalCellularAlt",
  },
  Advanced: {
    primaryColor: "deepRed",
    secondaryColor: "bgLightRed",
    icon: "FaSignal",
  },
};
