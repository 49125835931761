import React, { useState } from "react";
import CoursesListingView from "./CoursesListing.view";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import useAuthActions from "@/store/actions/authAction";

const CoursesListing = ({
  data,
  section_id,
  heading,
  showOn,
  failedAssessmentCTA,
}) => {
  const { auth } = useAuthActions();
  const [filters, setFilters] = useState({});
  const { tableData = [], apiData = {} } = data || {};
  const { assessments = [] } = apiData || {};
  const [appliedFilters, setAppliedFilters] = useState({});

  function sortByPriority(dataArray) {
    return dataArray?.sort((a, b) => b.priority - a.priority);
  }

  const addAssessmentsToData = (data, assessments) => {
    const assessmentMap = assessments.reduce((map, assessment) => {
      map[assessment.slug] = assessment;
      return map;
    }, {});

    return data.map((item) => {
      const slug = item.slug;
      if (slug && assessmentMap[slug]) {
        return {
          ...item,
          assessmentItem: assessmentMap[slug],
        };
      }
      return item;
    });
  };

  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="section-listing">
        <CoursesListingView
          failedAssessmentCTA={failedAssessmentCTA}
          id={section_id}
          heading={heading}
          assessments={assessments}
          isLoggedIn={auth?.token}
          addAssessmentsToData={addAssessmentsToData}
          data={sortByPriority(tableData).filter((item) => {
            return Object.keys(appliedFilters).every((key) => {
              if (appliedFilters[key].length === 0) {
                return true;
              }
              const filterValues = appliedFilters[key].map(
                (filter) => filter.label
              );

              if (key === "skills") {
                return item?.skills?.data?.some((skill) =>
                  filterValues.includes(skill?.display_name)
                );
              }

              if (key === "level") {
                return item?.level?.some((diffLevel) =>
                  filterValues.includes(diffLevel?.label)
                );
              }

              return filterValues.includes(item[key]);
            });
          })}
          setFilters={setFilters}
          filters={filters}
          setAppliedFilters={setAppliedFilters}
        />
      </section>
    </UseComponentVisibility>
  );
};

export default CoursesListing;
