import styled from "styled-components";
import { ColDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { H2 } from "@/outscal-commons-frontend/Styled/TextStyles";

export const CardListWrapper = styled(ColDiv)`
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: calc(var(--screen-lg) + 20px);
  margin: auto;
  padding: 20px;
  background-color: ${(p) => p.theme.colors.white};
  @media (max-width: 500px) {
    padding: 5px;
  }
`;

export const Heading = styled(H2)`
  font-size: ${(p) => p.theme.fontSize.xxxxl};
  margin: 0 auto;
  text-align: center;
  @media screen and (max-width: 600px) {
    padding: 5px;
  }
`;
