import React from "react";
import ListingCardView from "./ListingCard.view";
import { useRouter } from "next/router";
import { courseListingMap } from "./ListingCard.model";
import { convertStringToSlug } from "@/outscal-commons-frontend/utils/stringOperations";
import AssessmentListingCard from "../AssessmentListingCard/AssessmentListingCard.view";

const ListingCard = ({ id, item, customCardData, name }) => {
  const {
    link = "",
    type = "",
    premiumTagText = "",
    title = "",
    access_marker: accessMarker = "BASIC",
    skills = {},
  } = item || {};

  const { isLoggedIn = false, failedAssessmentCTA } = customCardData || {};
  const { data = [] } = skills || {};
  const router = useRouter();

  const disableClick = (e) => e.preventDefault();

  const handleListingCardClick = (e) => {
    e.preventDefault();
    router.push(link);
  };

  const sharedProps = {
    ...item,
    skills: data,
    scroll_id:
      isLoggedIn && type === "Assessment"
        ? `${item?.scroll_id}_new`
        : item?.scroll_id,
    premiumTagText,
    disableClick,
    isProPerk: accessMarker === "PRO",
    handleListingCardClick,
    analyticsText: `${convertStringToSlug(title)}_${type}_${name}`,
    ...courseListingMap[type],
  };

  if (type === "Assessment") {
    return (
      <AssessmentListingCard
        {...sharedProps}
        failedAssessmentCTA={failedAssessmentCTA}
      />
    );
  }

  return <ListingCardView {...sharedProps} />;
};

export default ListingCard;
