import React from "react";
import styles from "./ListingCard.module.css";
import Link from "next/link";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import FeatureTags from "./FeatureTags.controller";
import Image from "next/image";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import { difficultyColorsMap } from "./ListingCard.model";

const ListingCardView = ({
  image = {},
  level = [],
  title = "",
  uspTag = "",
  analyticsText,
  feeType = "",
  link = "",
  type = "",
  keys = {},
  scroll_id = null,
  premiumTagText = "",
  disableClick,
  handleListingCardClick,
  isFeatured = false,
  hasCertificate = true,
  hasPlacement = false,
  contentCount = 0,
  contentIncludes = "",
  styleClass,
  skills = [],
}) => (
  <div
    className={`${styles.root} ${
      isFeatured ? styles.featured : styles[`${styleClass}-root`]
    }`}
    id={scroll_id}
    onClick={handleListingCardClick}
    data-analytics={["CARD", analyticsText]}
    view-analytics={["CARD", analyticsText]}
  >
    <div className={styles.cardDetailsWrapper}>
      {image?.url && (
        <Image
          width={180}
          height={124}
          className={styles.cardImg}
          src={image.url}
          alt={title}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {feeType === "Free" && <div className={styles.type}>{feeType}</div>}
          {type && feeType === "Free" && <div className={styles.type}>·</div>}
          {type && <div className={styles.type}>{type}</div>}
          {contentCount && contentIncludes && (
            <>
              <div className={styles.type}>·</div>
              <div className={styles.type}>
                {contentCount} {contentIncludes}
              </div>
            </>
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <Link href={link} onClick={disableClick}>
            <Text fontSize="xl" fontWeight="midBold">
              {title}
            </Text>
          </Link>
          <FeatureTags tags={keys.keys} skills={skills} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              flexWrap: "wrap",
            }}
          >
            {level &&
              (level.length >= 2
                ? [level[0], level[level.length - 1]]
                : level
              ).map(
                (difficulty, index) =>
                  difficulty?.label && (
                    <React.Fragment key={difficulty.id}>
                      <div className={`${styles.type} ${styles.iconText}`}>
                        <DynamicIcon
                          icon={difficultyColorsMap[difficulty.label]?.icon}
                        />
                        {difficulty.label}
                      </div>
                      {index === 0 && level.length >= 2 && (
                        <span className={styles.type}>to</span>
                      )}
                    </React.Fragment>
                  )
              )}
            {hasCertificate && (
              <>
                <div className={styles.type}>·</div>
                <div className={`${styles.type} ${styles.iconText}`}>
                  <DynamicIcon icon="PiCertificate" color="#8b3dff" />
                  Certificate Eligible
                </div>
              </>
            )}
            {hasPlacement && (
              <>
                <div className={styles.type}>·</div>
                <div className={`${styles.type} ${styles.iconText}`}>
                  <DynamicIcon icon="BsCheck2Circle" color="#067519" />
                  Job Placement Support
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    {uspTag && (
      <div
        className={`${styles.uspTag} ${
          styles[
            isFeatured ? `${styleClass}-pill-featured` : `${styleClass}-pill`
          ]
        }`}
      >
        {uspTag}
      </div>
    )}
    {premiumTagText && (
      <div className={styles.premiumTag}>{premiumTagText}</div>
    )}
  </div>
);

export default ListingCardView;
