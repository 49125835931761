import React from "react";
import { CardListWrapper, Heading } from "./CoursesListing.styles";
import GridLayout from "@/mvcComponents/Commons/GridLayout/GridLayout.controller";
import ListingCard from "../../Cards/ListingCard/ListingCard.controller";
import CoursesListingFilters from "./CoursesListingFilters/CoursesListingFilters.controller";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";

const CoursesListingView = ({
  heading,
  data,
  id,
  setFilters,
  filters,
  setAppliedFilters,
  isLoggedIn,
  assessments,
  failedAssessmentCTA,
  addAssessmentsToData,
}) => {
  return (
    <>
      <Heading>{heading}</Heading>
      <CardListWrapper gap="12px" id={id}>
        <CoursesListingFilters
          setFilters={setFilters}
          filters={filters}
          setAppliedFilters={setAppliedFilters}
        />
        <GridLayout
          col={1}
          name={id}
          gridGap={16}
          justifyContent="space-evenly"
          cardComponent={ListingCard}
          listData={addAssessmentsToData(data, assessments)}
          customCardData={{ isLoggedIn, failedAssessmentCTA }}
        />
      </CardListWrapper>
    </>
  );
};

export default CoursesListingView;
