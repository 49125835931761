import React from "react";
import styles from "./AssessmentListingCard.module.css";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";

const FeatureTags = ({ tags, skills }) => {
  return tags?.length > 0 || skills?.length ? (
    <RowDiv wrap={true} gap="6px" alignItems="center">
      {tags?.length > 0 &&
        tags.map((item) => (
          <div className={`${styles.skillTag}`} key={item}>
            {item}
          </div>
        ))}
      {skills?.length > 0 &&
        skills.map((item) => (
          <div className={`${styles.skillTag}`} key={item}>
            {item.display_name}
          </div>
        ))}
    </RowDiv>
  ) : null;
};

export default FeatureTags;
